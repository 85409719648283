html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: PingFang SC;
}

ul {
  margin-bottom: 0;
  padding-left: 0;
}

ul li {
  list-style: none;
}

input {
  background: none;
  outline: none;
  border: 0px;
}

.container {
  box-sizing: border-box;
  margin: 0 auto;
  width: 1230px;
  padding: 0 15px;
  box-sizing: border-box;
}

.substring {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
}

.substring2 {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.section-card {
  box-sizing: border-box;
  padding: 20px;
  background-color: #fff;
  border-radius: 0px;
  margin-top: 16px;
  width: 100%;
}

.section-title-wrap {
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .title-wrap {
    display: flex;
    align-items: center;
  }
  .title {
    font-weight: 500;
    font-size: 20px;
    color: #000000;
  }
}
